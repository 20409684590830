<div class="user-box">
    <div class="text-center">
        <div class="text-center"><span class="required">*</span> Fields are required for Accountant Partner.</div>
        <div class="text-center"><span class="required-for-rockstar">*</span> Fields are required for RockStar
            Publication.</div>
    </div>
    <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper"
        (selectionChange)="stepChanged()">
        <mat-step [stepControl]="personalDetailsFormGroup" state="phone">
            <form [formGroup]="personalDetailsFormGroup">
                <ng-template matStepLabel>Personal Details</ng-template>
                <div class="row">
                    <div class="col-md-4 text-center">
                        <div class="app-avatar-wrapper">
                            <div *ngIf="userExists">
                                <div class="app-avatar" *ngIf="profileImageFromAws">
                                    <img class="rounded-circle app-avatar-img" src="{{profileImageFromAws}}"
                                        alt="Profile Pic">
                                </div>
                                <div *ngIf="!profileImageFromAws">
                                    <div class="app-avatar" *ngIf="userProfilePic">
                                        <img class="rounded-circle app-avatar-img" src="{{userProfilePic}}"
                                            alt="Profile Pic">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!userExists">
                                <img src="assets/images/male.png" class="rounded-circle app-avatar-img">
                            </div>
                            <!-- <button mat-icon-button class="app-bg-primary app-avatar-btn">
                                <mat-icon>add</mat-icon>
                            </button> -->
                        </div>
                        <label class="app-image-wrapper mt-2" *ngIf="userExists">
                            Update Profile Picture
                            <input type="file" matTooltip="Update profile image" accept=".jpg, .jpeg, .png" hidden=""
                                (change)="onUpdateProfileImage($event)">
                        </label>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label"
                                        [ngClass]="{'required-error': personalDetailsFormGroup?.controls?.firstName?.errors?.required}">First
                                        Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="firstName"
                                        placeholder="First Name" name="firstName" id="firstName" required>
                                    <div *ngIf="personalDetailsControl.firstName.invalid && (personalDetailsControl.firstName.dirty || personalDetailsControl.firstName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.firstName.errors.required">First name is
                                            required.</div>
                                        <div *ngIf="personalDetailsControl.firstName.errors.maxlength">First name should
                                            not be greater than 75 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label"
                                        [ngClass]="{'required-error': personalDetailsFormGroup?.controls?.lastName?.errors?.required}">Last
                                        Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="lastName"
                                        placeholder="Last Name" name="lastName" id="lastName" required>
                                    <div *ngIf="personalDetailsControl.lastName.invalid && (personalDetailsControl.lastName.dirty || personalDetailsControl.lastName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.lastName.errors.required">Last name is
                                            required.</div>
                                        <div *ngIf="personalDetailsControl.lastName.errors.maxlength">Last name should
                                            not be greater than 75 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="control-label"
                                        [ngClass]="{'required-error': personalDetailsFormGroup?.controls?.companyName?.errors?.required}">Company
                                        Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="companyName"
                                        placeholder="Company Name" name="company" id="company" required>
                                    <div *ngIf="personalDetailsControl.companyName.invalid && (personalDetailsControl.companyName.dirty || personalDetailsControl.companyName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.companyName.errors.required">Company Name is
                                            required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="control-label"
                                        [ngClass]="{'required-error': personalDetailsFormGroup?.controls?.title?.errors?.required}">Rockstar
                                        Profile Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="rockStarProfileName"
                                        placeholder="Unique Identifier for Rockstar" name="rockStarProfileName"
                                        id="rockStarProfileName" required>
                                    <div *ngIf="personalDetailsControl.rockStarProfileName.invalid && (personalDetailsControl.rockStarProfileName.dirty || personalDetailsControl.rockStarProfileName.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.rockStarProfileName.errors.required">Rockstar
                                            Profile Name is required.</div>
                                        <div *ngIf="personalDetailsControl.rockStarProfileName.errors.unique">Rockstar
                                            Profile Name should be unique.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <h5>Basic Information</h5>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label"
                                        [ngClass]="{'required-error': personalDetailsFormGroup?.controls?.title?.errors?.required}">Title
                                        <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="title" placeholder="Title"
                                        name="title" id="title" required>
                                    <div *ngIf="personalDetailsControl.title.invalid && (personalDetailsControl.title.dirty || personalDetailsControl.title.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.title.errors.required">Title is
                                            required.</div>
                                        <div *ngIf="personalDetailsControl.title.errors.maxlength">Title should
                                            not be greater than 20 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label">Country <span class="required">*</span></label>
                                    <select type="text" class="form-control" formControlName="country"
                                        placeholder="Title" name="country" id="country" required>
                                        <option *ngFor="let country of countries" value="{{country.countryName}}">
                                            {{country.countryName}}</option>
                                    </select>
                                    <div *ngIf="personalDetailsControl.country.invalid && (personalDetailsControl.country.dirty || personalDetailsControl.country.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.country.errors.required">
                                            Country is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="control-label">Details <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <textarea type="text" class="form-control" formControlName="userDetails"
                                        placeholder="" name="userDetails" id="userDetails" rows="3"
                                        placeholder="Required for Rockstar"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <h5>Contact Details</h5>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label">Phone</label>
                                    <ngx-intl-tel-input
                                        [inputId]="'phoneNumber'"  
                                        [cssClass]="'form-control intl-tel-input'" 
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" 
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="searchCountryField" 
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.UnitedStates"
                                        [preferredCountries]="preferredCountries" 
                                        [maxLength]="10"
                                        [phoneValidation]="true" 
                                        [separateDialCode]="separateDialCode"
                                        name="phoneNumber" 
                                        formControlName="phoneNumber">
                                    </ngx-intl-tel-input>
                                    <div *ngIf="personalDetailsControl.phoneNumber.invalid && (personalDetailsControl.phoneNumber.dirty || personalDetailsControl.phoneNumber.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.phoneNumber.errors.validatePhoneNumber">
                                            Please enter a valid Phone number. </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label">Website</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">https://</span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="website"
                                        placeholder="Website" name="website" id="website" pattern>
                                        <div class="input-group-append">
                                            <a class="btn btn-primary" target="_blank" href="https://{{personalDetailsControl.website.value}}">Open</a>
                                        </div>
                                    </div>
                                    <div *ngIf="personalDetailsControl.website.invalid && (personalDetailsControl.website.dirty || personalDetailsControl.website.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="personalDetailsControl.website.errors.pattern">Please enter a valid
                                            link. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <button class="btn btn-primary ms-2 float-end" (click)="updateUserDetails()"
                                [disabled]="personalDetailsFormGroup.invalid">Submit</button>
                            <button class="btn btn-primary float-end" matStepperNext
                                [disabled]="personalDetailsFormGroup.invalid">Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="companyDetailsFormGroup" [optional]="true" state="chat">
            <form [formGroup]="companyDetailsFormGroup">
                <ng-template matStepLabel>Company Details</ng-template>
                <div class="row">
                    <div class="col-md-4 text-center">

                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label"
                                        [ngClass]="{'required-error': companyDetailsFormGroup?.controls?.yearInBusiness?.errors?.required}">Years
                                        in Business</label>
                                    <input type="number" min="0" max="100" class="form-control"
                                        formControlName="yearInBusiness" placeholder="Number of years"
                                        name="yearInBusiness" id="yearInBusiness">
                                    <div *ngIf="companyDetailsFormControl.yearInBusiness.invalid && (companyDetailsFormControl.yearInBusiness.dirty || companyDetailsFormControl.yearInBusiness.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="companyDetailsFormControl.yearInBusiness.errors.pattern">Only
                                            Numbers
                                            allowed.</div>
                                        <div *ngIf="companyDetailsFormControl.yearInBusiness.errors.min">Years in
                                            Business
                                            count should be above 0.</div>
                                        <div *ngIf="companyDetailsFormControl.yearInBusiness.errors.max">Years in
                                            Business
                                            count should be below 100.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="control-label">Company Details</label>
                                    <textarea class="form-control" formControlName="companyDetails"
                                        placeholder="Company Details" name="companyDetails" id="companyDetails"
                                        rows="3"></textarea>
                                    <div *ngIf="companyDetailsFormControl.companyDetails.invalid && (companyDetailsFormControl.companyDetails.dirty || companyDetailsFormControl.companyDetails.touched)"
                                        class="invalid-feedback">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label">Phone Number <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <ngx-intl-tel-input
                                        [inputId]="'companyPhoneNumber'" 
                                        [cssClass]="'form-control intl-tel-input'" 
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" 
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="searchCountryField"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.UnitedStates"
                                        [preferredCountries]="preferredCountries" 
                                        [maxLength]="10"
                                        [phoneValidation]="true" 
                                        [separateDialCode]="separateDialCode"
                                        name="phoneNumber" 
                                        formControlName="phoneNumber">
                                    </ngx-intl-tel-input>
                                    <div *ngIf="companyDetailsFormControl.phoneNumber.invalid && (companyDetailsFormControl.phoneNumber.dirty || companyDetailsFormControl.phoneNumber.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="companyDetailsFormControl.phoneNumber.errors.validatePhoneNumber">
                                            Please enter a valid Phone number. </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="control-label">Website</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">https://</span>
                                        </div>
                                        <input class="form-control" formControlName="website"
                                            placeholder="Company Website" name="website" id="website">
                                        <div class="input-group-append">
                                            <a class="btn btn-primary" target="_blank" href="https://{{companyDetailsFormControl.website.value}}">Open</a>
                                        </div>
                                    </div>
                                    <div *ngIf="companyDetailsFormControl.website.invalid && (companyDetailsFormControl.website.dirty || companyDetailsFormControl.website.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="companyDetailsFormControl.website.errors.pattern">Please enter a
                                            valid link. </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <h5>Company Address</h5>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">Street <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <input class="form-control" formControlName="addressLine1" placeholder="Street"
                                        name="addressLine1" id="addressLine1">
                                </div>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">Address Line 1</label>
                                    <input class="form-control" formControlName="addressLine2"
                                        placeholder="Address Line 1" name="addressLine2" id="addressLine2">
                                </div>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">City <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <input class="form-control" formControlName="city" placeholder="City" name="city"
                                        id="city">
                                </div>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">Zip Code <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <input class="form-control" formControlName="zipCode" placeholder="Zip Code"
                                        name="zipCode" id="zipCode">
                                </div>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">Country <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <select class="form-control" formControlName="country" placeholder="Select Country"
                                        name="country" id="country">
                                        <option *ngFor="let country of countries" value="{{country.countryName}}">
                                            {{country.countryName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">State <span class="required-for-rockstar"
                                            matTooltip="Required for RockStar">*</span></label>
                                    <select class="form-control" formControlName="state" placeholder="Select State"
                                        name="state" id="state">
                                        <option *ngFor="let state of states" value="{{state.name}}">
                                            {{state.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12"><strong>Note:</strong> Latitude/Longitude values automatically update
                                based on entered Zip Code.</div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">Latitude </label>
                                    <input class="form-control" formControlName="latitude" placeholder="latitude"
                                        name="latitude" id="latitude" readonly>
                                </div>
                            </div>
                            <div class="col-md-6" formGroupName="address">
                                <div class="mb-3">
                                    <label class="control-label">Longitude </label>
                                    <input class="form-control" formControlName="longitude" placeholder="longitude"
                                        name="longitude" id="longitude" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <button class="btn btn-primary" matStepperPrevious>Back</button>
                            <button class="btn btn-primary ms-2 float-end" matStepperNext
                                [disabled]="companyDetailsFormGroup.invalid" (click)="getGeoDetails();">Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="otherDetailsFormGroup" [optional]="true">
            <form [formGroup]="otherDetailsFormGroup">
                <ng-template matStepLabel>Other Details</ng-template>
                <div class="row">
                    <div class="col-md-12 pt-2 pb-2">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Rockstar Settings
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-checkbox color="primary" name="hideEmailInRockstar"
                                                formControlName="hideEmailInRockstar" value="hideEmailInRockstar"> Hide
                                                Email Address
                                                in public profile</mat-checkbox>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-checkbox color="primary" name="hidePhoneNoInRockstar"
                                                formControlName="hidePhoneNoInRockstar" value="hidePhoneNoInRockstar">
                                                Hide
                                                Phone number in
                                                public profile</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Services Provided
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content" formGroupName="serviceProvided">
                                    <div class="row">
                                        <div *ngFor="let service of servicesProvidedOptions;" class="col-md-6">
                                            <mat-checkbox color="primary" formControlName="{{service.control}}"
                                                [value]="service.name">{{service.name}}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Industries Served
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content" formGroupName="industriesServed">
                                    <div class="row">
                                        <div *ngFor="let industriesServed of industriesServedOptions" class="col-md-6">
                                            <mat-checkbox color="primary" formControlName="{{industriesServed.control}}"
                                                [value]="industriesServed.name">{{industriesServed.name}}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Languages Spoken
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content" formGroupName="languageSpoken">
                                    <div class="row">
                                        <div *ngFor="let language of languageSpokenOptions" class="col-md-6">
                                            <mat-checkbox color="primary" formControlName="{{language.control}}"
                                                [value]="language.name">{{language.name}}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Professional Designations
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content" formGroupName="professionalDesignations">
                                    <div class="row">
                                        <div *ngFor="let professionalDesignations of professionalDesignationsOptions"
                                            class="col-md-6">
                                            <mat-checkbox color="primary"
                                                formControlName="{{professionalDesignations.control}}"
                                                [value]="professionalDesignations.name">
                                                {{professionalDesignations.name}}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <button class="btn btn-primary" matStepperPrevious>Back</button>
                            <button class="btn btn-primary float-end" (click)="updateUserDetails()">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <!-- Icon overrides. -->
        <ng-template matStepperIcon="phone">
            <mat-icon>person_pin</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="chat">
            <mat-icon>business</mat-icon>
        </ng-template>
    </mat-horizontal-stepper>
    <ngx-ui-loader [loaderId]="'add-profile-loader'" *ngIf="primaryColor" fgsColor="{{primaryColor}}"
        pbColor="{{primaryColor}}"></ngx-ui-loader>
</div>
